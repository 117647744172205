import React from 'react';
import classNames from 'classnames';
import styles from './welcome_message.module.scss';

import close from '../assets/icons/close.svg';
import Rotation from '../assets/icons/3d_rotation.svg';
import Sphere from '../assets/icons/baseline-trip_origin-24px.svg';

const WelcomeMessage = ({ message, closeWelcomeMessage, show }) => (
  <div className={classNames(styles.WelcomeMessage, { [styles.hide]: !show })}>
    <div className={styles.WelcomeMessage_box}>
      <div className={styles.WelcomeMessage_header}>
        <h4>Welcome to the Weup airmap of Itakeskus</h4>
        <img onClick={closeWelcomeMessage} src={close} alt="close" />
      </div>
      <div className={styles.WelcomeMessage_body}>
        <div className={styles.message_text}></div>
        <img src={Rotation} alt="Rotation" />
        <h4>Click and hold to rotate</h4>
      </div>
      <div className={styles.WelcomeMessage_body}>
        <div className={styles.message_text}></div>
        <img src={Sphere} alt="Rotation" />
        <h4>Click on the spheres to change scene</h4>
      </div>
      <div className={styles.WelcomeMessage_footer}>
        <h5 className={styles.WelcomeMessage_footer__message}>
          Updated 01.08.2020
        </h5>
        <button onClick={closeWelcomeMessage}>Got it</button>
      </div>
    </div>
  </div>
);

export default WelcomeMessage;
