import React, { Component } from "react";
import { connect } from "react-redux";
//import classNames from 'classnames';
import {
  onHoverSubCategory,
  unHoverSubCategory
} from "../redux/actions/notifications";

import { setSelectedSubcategory } from "../redux/actions/portableState";

import Hotspot from "../Hotspot";

class Hotspots extends Component {
  
  getHotspots = () => {
    const {
      portableState,
      subCategoryHover,
      chosenSubCategory,
      onHoverSubCategory,
      unHoverSubCategory,
      rotateDone
    } = this.props;


    if(portableState.screenCoordinates) {
      
    return Object.keys(portableState.screenCoordinates).map((subcategoryId) => {
      const coordinates = portableState.screenCoordinates[subcategoryId];
      const category = Object.values(portableState.categories).find(
        (c) => c.subcategories[subcategoryId] !== undefined
      );
      const subcategoryData = category.subcategories[subcategoryId];
      return (
        <Hotspot
          key={subcategoryId}
          coordinates={coordinates}
          data={subcategoryData}
          subCategoryHover={subCategoryHover}
          chosenSubCategory={chosenSubCategory}
          onChoseSubCategory={this.onChoseSubCategory}
          onHover={onHoverSubCategory}
          unHover={unHoverSubCategory}
          rotateDone={rotateDone}
        />
      );
    });
  };
  };
  

  onChoseSubCategory = async (subcategory) => {
    this.props.setSelectedSubcategory(subcategory.id);
  };

  render() {
    return <div>{this.getHotspots()}</div>;
  }
}

function mapStateToProps(state) {
  return {
    portableState: state.portableState,
    notifications: state.notifications,
    chosenCategory: state.portableState.activeCategory,
    chosenSubCategory: state.portableState.activeSubcategory,
    subCategoryHover: state.notifications.subCategoryHover,
    rotateDone: state.notifications.rotateDone
  };
}

export default connect(
  mapStateToProps,
  { setSelectedSubcategory, onHoverSubCategory, unHoverSubCategory }
)(Hotspots);
