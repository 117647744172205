import React, { Component } from "react";
import { connect } from "react-redux";
import cookie from "react-cookies";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./App.css";
import Categories from "./Categories";
import SubCategoryDetails from "./SubCategoryDetails";
import Hotspots from "./Hotspots";
import WelcomeMessage from "./WelcomeMessage";
import Credits from "./Credits";
import ViewOptionsPanel from "./components/ViewOptionsPanel";

const cesiumAssets = window.cesiumAssets;

class App extends Component {
  state = {
    firstTimeRunning: false
  };

  componentDidMount() {
    const firstTimeRunning = cookie.load("firstTime");

    if (!firstTimeRunning) {
      cookie.save("firstTime", "firstTime");
      this.setState({ firstTimeRunning: true });
    }
  }

  closeWelcomeMessage = () => {
    this.setState({ firstTimeRunning: false });
  };

  render() {
    return (
      <div className="horizontalCoordinates">
        {this.props.portableState.categories && (
          <div>
            <ViewOptionsPanel cesiumAssets={cesiumAssets} />
            <SubCategoryDetails />
            <Categories />
            <Hotspots />
            <Credits />
            <WelcomeMessage
              closeWelcomeMessage={this.closeWelcomeMessage}
              show={this.state.firstTimeRunning}
            />
          </div>
        )}

        <div />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    portableState: state.portableState
  };
}

export default connect(mapStateToProps, null)(App);
